// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".opt-hover {\n  margin: 0 !important;\n  border: 1px solid #c4c4c4 !important;\n  border-radius: 50px !important;\n  box-shadow: none !important;\n}\n.selected-opt {\n  background: #e6f7ff;\n  border-radius: 50px;\n  margin-bottom: 8px;\n}\n.correct-opt {\n  background: #defffb;\n  border-radius: 50px;\n  margin-bottom: 8px;\n}\n.wrong-opt {\n  background: #ffebeb;\n  border-radius: 50px;\n  margin-bottom: 8px;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
