<template>
  <v-container>
    <div v-if="!readonly">
      <v-form v-model="answerValid">
        <v-textarea
          v-model="studentAnswer"
          outlined
          :readonly="readonly"
          placeholder="Type in your answer"
          :rules="[v => maxLength(v?.trim(), limit ?? 8000, `Answer`)]"
        ></v-textarea>
      </v-form>
      <v-row justify="center">OR</v-row>
      <v-file-input v-model="file" outlined label="Upload File"></v-file-input>
      <v-row v-if="!readonly" justify="end">
        <v-btn
          color="primary"
          :loading="submitting"
          :disabled="!answerValid"
          @click="submitAnswer"
          >Submit
        </v-btn>
      </v-row>
      <v-container v-if="readonly && answer.explanation" class="px-0">
        <answer-explanation :answers="answer" />
      </v-container>
    </div>
    <div v-else>
      <v-textarea
        :value="value.answer_id"
        outlined
        :readonly="readonly"
      ></v-textarea>
      <v-btn v-if="value.file_url" color="primary" @click="download"
        >Download Answer
      </v-btn>
      <v-btn
        v-if="value.assessed_url"
        color="primary"
        class="ml-3"
        @click="$emit('preview')"
        >Preview Answer
      </v-btn>
      <div class="d-flex align-center">
        <v-text-field
          :value="value.score"
          :readonly="readonly"
          label="Score"
          outlined
          dense
          type="number"
          class="mt-3"
          hide-details
        ></v-text-field>
      </div>
    </div>
  </v-container>
</template>

<script>
import AnswerExplanation from "#ef/exam/components/AnswerExplanation";
import { FieldValidations } from "/global/utils/validations";

export default {
  name: "AnswerCardDesc",
  components: { AnswerExplanation },
  mixins: [FieldValidations],
  props: {
    answer: {
      type: Object,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: { type: [String, Object], default: undefined },
    submitting: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      studentAnswer: "",
      file: undefined,
      answerValid: undefined
    };
  },
  methods: {
    submitAnswer() {
      this.$emit("input", {
        value: this.studentAnswer.trim(),
        filename: this.file
      });
    },
    download() {
      window.open(this.value.file_url);
    }
  }
};
</script>

<style scoped></style>
