var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    { staticClass: "d-flex justify-center" },
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _c(
            "v-container",
            { staticClass: "q-a-card" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "div",
                    {
                      staticClass: "mb-2",
                      staticStyle: { "text-align": "justify" },
                    },
                    [
                      _vm.question.item_text
                        ? _c("div", {
                            staticClass: "ml-4 item-text-style",
                            staticStyle: {
                              overflow: "hidden",
                              height: "max-content",
                            },
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.format(
                                  `<h3 class='mb-3'>Question No: ${
                                    _vm.question.order + 1
                                  }</h3>` + _vm.question.item_text
                                )
                              ),
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _vm.question.file_url
                    ? _c(
                        "div",
                        { staticClass: "mb-2 d-flex justify-center" },
                        [
                          _c("v-img", {
                            attrs: {
                              src: _vm.question.file_url,
                              "max-width": "300",
                              "max-height": "300",
                              contain: "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("v-divider"),
                ],
                1
              ),
              _vm.questionType === "mcq"
                ? _c(
                    "v-container",
                    { staticClass: "px-8 pt-0" },
                    [
                      _vm._l(_vm.question.answers, function (answer) {
                        return _c(
                          "div",
                          { key: answer.id },
                          [
                            _c("answer-opt-p", {
                              attrs: {
                                answer: answer,
                                selected: _vm.value.includes(answer.id),
                                correct: !!answer.is_correct,
                                wrong:
                                  answer.is_correct !== undefined &&
                                  _vm.value.includes(answer.id) &&
                                  !answer.is_correct,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.toggleSelect(answer.id)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      _vm.readonly
                        ? _c(
                            "v-container",
                            { staticClass: "px-0" },
                            [
                              _c("answer-explanation", {
                                attrs: { answers: _vm.question.answers },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm.questionType === "fib"
                ? _c(
                    "v-container",
                    { staticClass: "px-8 pt-0" },
                    [
                      _vm.readonly
                        ? _c("fib-answer-card", {
                            attrs: {
                              answer: _vm.question.answers[0],
                              readonly: _vm.readonly,
                              value: _vm.value[0],
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$emit("input", $event)
                              },
                            },
                          })
                        : _c("fib-answer-card", {
                            attrs: {
                              readonly: _vm.readonly,
                              value: _vm.value[0],
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$emit("input", $event)
                              },
                            },
                          }),
                    ],
                    1
                  )
                : _vm.questionType === "desc"
                ? _c(
                    "v-container",
                    { staticClass: "px-8 pt-0" },
                    [
                      _c("answer-card-desc", {
                        attrs: {
                          submitting: _vm.submitting,
                          readonly: _vm.readonly,
                          value: _vm.value[0],
                          limit: _vm.question.word_limit,
                        },
                        on: {
                          preview: function ($event) {
                            return _vm.$emit("preview")
                          },
                          input: function ($event) {
                            return _vm.$emit("input", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }