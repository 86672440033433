var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    {
      class: {
        "selected-opt": _vm.selected,
        "correct-opt": _vm.correct,
        "wrong-opt": _vm.wrong,
      },
      staticStyle: { padding: "1px !important", "margin-bottom": "5px" },
    },
    [
      _c("v-hover", {
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ hover }) {
              return [
                _c(
                  "div",
                  {
                    class: { "opt-hover": hover },
                    staticStyle: { margin: "1px" },
                  },
                  [
                    _c(
                      "m-container",
                      { staticClass: "pa-0" },
                      [
                        _c(
                          "v-row",
                          { staticClass: "my-0" },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass:
                                  "pl-2 pr-0 py-2 d-flex align-center justify-center flex-grow-0",
                                attrs: { cols: "2", sm: "1", md: "1" },
                              },
                              [
                                _vm.wrong
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "red accent-2" } },
                                      [_vm._v("cancel")]
                                    )
                                  : _vm.correct
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "teal darken-2" } },
                                      [_vm._v("check_circle")]
                                    )
                                  : _vm.selected
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "primary" } },
                                      [_vm._v("check_circle")]
                                    )
                                  : _c("v-icon", [
                                      _vm._v("radio_button_unchecked"),
                                    ]),
                              ],
                              1
                            ),
                            _c(
                              "v-col",
                              { staticClass: "pl-2 py-2 d-flex align-center" },
                              [
                                _vm.answer.item_text
                                  ? _c("div", {
                                      staticClass: "ml-4",
                                      staticStyle: {
                                        overflow: "hidden",
                                        height: "max-content",
                                      },
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.format(_vm.answer.item_text)
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm.answer.file_url
                              ? _c(
                                  "v-col",
                                  { staticClass: "mb-2 d-flex justify-center" },
                                  [
                                    _c("v-img", {
                                      attrs: {
                                        src: _vm.answer.file_url,
                                        "max-width": "300",
                                        "max-height": "300",
                                        contain: "",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }