<template>
  <v-container>
    <div v-if="!readonly">
      <v-text-field
        v-model="studentFibAnswer"
        @blur="$emit('input', studentFibAnswer.trim())"
      ></v-text-field>
      <v-container v-if="readonly && answer.explanation" class="px-0">
        <answer-explanation :answers="answer" />
      </v-container>
    </div>
    <v-container v-else>
      <v-row v-if="answer.item_text === value">
        <v-col
          cols="2"
          sm="1"
          md="1"
          class="pl-2 pr-0 py-2 d-flex align-center justify-center flex-grow-0"
          ><v-icon color="teal darken-2">check_circle</v-icon></v-col
        >
        <v-col class="pl-2 py-2 d-flex align-center">
          <v-text-field v-model="value" readonly></v-text-field>
        </v-col>
      </v-row>
      <div v-else>
        <v-row>
          <v-col
            cols="2"
            sm="1"
            md="1"
            class="pl-2 pr-0 py-2 d-flex align-center justify-center flex-grow-0"
            ><v-icon color="red accent-2">cancel</v-icon></v-col
          >
          <v-col class="pl-2 py-2 d-flex align-center">
            <v-text-field v-model="value" readonly />
          </v-col>
        </v-row>
        <v-row>
          Correct Answer:
          <strong class="ml-1">
            <v-flex
              v-if="answer.item_text"
              style="overflow: hidden; max-height: 9vh;"
              class="ml-4 d-flex"
              v-html="format(answer.item_text)"
            >
            </v-flex
          ></strong>
        </v-row>
      </div>
    </v-container>
  </v-container>
</template>

<script>
const math = /\$([^$]*)\$/g;

import katex from "katex/dist/katex.mjs";

export default {
  name: "FibAnswerCard",
  props: {
    answer: {
      type: Object,
      required: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    value: { type: String, default: undefined },
  },
  data() {
    return {
      studentFibAnswer: "",
    };
  },
  methods: {
    format(text) {
      if (text.includes("$")) {
        return text.replace(math, (match, txt) => {
          const rendered = katex.renderToString(txt, {
            displayMode: true,
            leqno: false,
            fleqn: false,
            throwOnError: false,
            errorColor: "#cc0000",
            strict: "warn",
            macros: { "\\f": "f(#1)" },
          });
          return rendered;
        });
      } else {
        return text;
      }
    },
  },
};
</script>

<style scoped></style>
