var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", [
    !_vm.readonly
      ? _c(
          "div",
          [
            _c(
              "v-form",
              {
                model: {
                  value: _vm.answerValid,
                  callback: function ($$v) {
                    _vm.answerValid = $$v
                  },
                  expression: "answerValid",
                },
              },
              [
                _c("v-textarea", {
                  attrs: {
                    outlined: "",
                    readonly: _vm.readonly,
                    placeholder: "Type in your answer",
                    rules: [
                      (v) =>
                        _vm.maxLength(v?.trim(), _vm.limit ?? 8000, `Answer`),
                    ],
                  },
                  model: {
                    value: _vm.studentAnswer,
                    callback: function ($$v) {
                      _vm.studentAnswer = $$v
                    },
                    expression: "studentAnswer",
                  },
                }),
              ],
              1
            ),
            _c("v-row", { attrs: { justify: "center" } }, [_vm._v("OR")]),
            _c("v-file-input", {
              attrs: { outlined: "", label: "Upload File" },
              model: {
                value: _vm.file,
                callback: function ($$v) {
                  _vm.file = $$v
                },
                expression: "file",
              },
            }),
            !_vm.readonly
              ? _c(
                  "v-row",
                  { attrs: { justify: "end" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          loading: _vm.submitting,
                          disabled: !_vm.answerValid,
                        },
                        on: { click: _vm.submitAnswer },
                      },
                      [_vm._v("Submit ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.readonly && _vm.answer.explanation
              ? _c(
                  "v-container",
                  { staticClass: "px-0" },
                  [
                    _c("answer-explanation", {
                      attrs: { answers: _vm.answer },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("v-textarea", {
              attrs: {
                value: _vm.value.answer_id,
                outlined: "",
                readonly: _vm.readonly,
              },
            }),
            _vm.value.file_url
              ? _c(
                  "v-btn",
                  { attrs: { color: "primary" }, on: { click: _vm.download } },
                  [_vm._v("Download Answer ")]
                )
              : _vm._e(),
            _vm.value.assessed_url
              ? _c(
                  "v-btn",
                  {
                    staticClass: "ml-3",
                    attrs: { color: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("preview")
                      },
                    },
                  },
                  [_vm._v("Preview Answer ")]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("v-text-field", {
                  staticClass: "mt-3",
                  attrs: {
                    value: _vm.value.score,
                    readonly: _vm.readonly,
                    label: "Score",
                    outlined: "",
                    dense: "",
                    type: "number",
                    "hide-details": "",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }